import axios from 'axios'
import global from '@/config/global'
import dayjs from 'dayjs'
import { exportResponseData } from '../utils/exportExcel'
import Cookie from "js-cookie";

const request = axios.create({
  baseURL: global.exportBaseApi,
  timeout: 600000 // 设置请求超时时间为 60s
})

// 请求拦截器：在请求发送前做一些处理，例如添加请求头等
request.interceptors.request.use(config => {
  // 添加请求头
  const access_token = Cookie.get("myCookie")
  config.headers['Access-Token'] = access_token
  config.headers['Content-Type'] = 'application/json'
  // get请求映射params参数
  if ((config.method === 'get' || config.method === 'delete') && config.data) {
    let url = config.url + '?' + tansParams(config.data);
    url = url.slice(0, -1);
    config.data = {};
    config.url = url;
  }
  if (config.responseType === 'blob') {
    config.headers['responseType'] = 'blob'
  }
  return config
}, error => {
  console.error(error)
  return Promise.reject(error)
})

// 响应拦截器：在响应返回后做一些处理，例如统一处理错误信息等
request.interceptors.response.use(response => {
  // 未设置状态码则默认成功状态
  const code = response.data.code || 200;
  // 获取错误信息
  const msg = response.data.msg || '系统错误'
  /**
     * 判断是否为excel文件【主要代码】
     */
  if (response.config.isExportExcel) {
    const data = response.data
    const contentType = response.headers['content-type']
    let fileName
    if (response.headers['content-disposition']) {
      fileName = decodeURI(
        response.headers['content-disposition'].match(/filename=(.*)/)[1]
      )
    } else {
      fileName = dayjs().format('YYYYMMDD')
    }
    exportResponseData(data, contentType, fileName)
    return
  }
  // 处理响应数据
  return response.data
}, error => {
  // 处理错误信息
  console.error(error)
  return Promise.reject(error)
})

/**
 * 请求数据
 */
export const getData = config => {
  return request({
    ...config
  })
}



/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}

/**
 * 导出 excel 数据【主要代码】
 */
export const exportExcel = config => {
  return request({
    method: 'post',
    isExportExcel: true,
    responseType: 'blob',
    ...config
  })
}

export default request
